import { useState, useEffect } from "react";
import gg from "../images/gg.png";
import { Link } from "react-router-dom";

const fetchNotices = async () => {
  try {
    const response = await fetch('https://htadmin.biandanbao.com/api/news/all');
    if (!response.ok) {
      throw new Error('网络响应不正常');
    }
    return await response.json();
  } catch (error) {
    console.error("获取公告失败:", error);
    return [{ title: "获取公告失败，请稍后再试。", publish_time: new Date().toISOString().split('T')[0] }];
  }
};

const Notice = ({ width = "1200px" }) => {
  const [noticeList, setNoticeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const getNotices = async () => {
      setLoading(true);
      const data = await fetchNotices();
      setNoticeList(data.data);
      setLoading(false);
    };

    getNotices();
  }, []);

  useEffect(() => {
    if (noticeList.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        // console.log('Previous index:', prevIndex);
        // console.log('Notice list length:', noticeList.length);
        if (isNaN(prevIndex) || prevIndex < 0) {
          console.log('Resetting index to 0');
          return 0;
        }
        return (prevIndex + 1) % noticeList.length;
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [noticeList]);

  // if (loading) {
  //   return <div className="text-center">加载中...</div>;
  // }
  console.log(currentIndex);
  const currentNotice = noticeList[currentIndex] || { content: "", time: "" };

  return (
    <div
      className={`flex justify-between items-center bg-[#f1f1f1] h-full w-[${width}] rounded-full relative pl-[50px] pr-[70px]`}
    >
      <img
        src={gg}
        className="absolute left-[10px] top-[11px] w-[26px] h-[26px]"
        alt="公告图标"
      />
      <div className="flex items-center pl-4 flex-grow overflow-hidden">
        <span className="text-gray-400 text-sm pl-2 mr-[30px] whitespace-nowrap">网站公告:</span>
        <div className="overflow-hidden whitespace-nowrap flex-grow">
          <div className="inline-block ">
            <Link to={`/news-detail/?id=${currentNotice.id}`}><span className="text-black text-sm">{currentNotice.title}</span></Link>
          </div>
        </div>
      </div>
      <div className="flex items-center ml-[30px]">
        <span className="text-gray-400 text-sm whitespace-nowrap">{currentNotice.publish_time}</span>
      </div>
    </div>
  );
};

export default Notice;
