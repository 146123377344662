const Right = () => {
  return (
    <div className="right w-[300px] bg-[#f5f5f5]	py-[15px] px-[20px] h-[652px] rounded-[20px]">
      {/* <h2 className="font-bold text-lg	my-[20px]">汇旺担保TG官方用户名</h2>
      <div className="contact">
        <p>
          咨询/解封、广告业务、资源对接、会员办理、专群业务、公群业务、纠纷申报
          联系唯一客服@hwdb（汇旺担保首字母）
        </p>
      </div>
      <div className="important">
        <h5>重要说明：</h5>
        <ol>
          <li>
            1. @hwtb1 到 @hwtb999
            都是汇旺担保官方账号,"汇旺淘宝”首字母“hwtb”简单好记！
          </li>
        </ol>
      </div>
      <div className="tips font-bold ">
        <p>
          温馨提示：群内所有服务由商户提供，请联系带“群老板”、“业务员”字样的商户方管理员进行交易，汇旺担保只提供担保服务。
        </p>
      </div> */}
      <div className="flex flex-col font-['Roboto_Mono']">
        <h1 className="self-start font-bold	">币安社群</h1>
        <p>
          官方频道 <a href="https://t.me/bian">@BIAN</a> 官方频道
        </p>
        <p>
          币安担保 <a href="https://t.me/badb">@BADB</a> 每日公群
        </p>
        <p>
          币安公群 <a href="https://t.me/bagq">@BAGQ</a> 静态导航
        </p>
        <p>
          币安供需 <a href="https://t.me/bagx">@BAGX</a> 付费广告
        </p>
        <p>
          纠纷仲裁 <a href="https://t.me/uber">@UBER</a> 防骗曝光
        </p>
        <h1 className="self-start font-bold	">工作人员</h1>
        <p>
          公群负责人 <a href="https://t.me/cpdd">@CPDD</a>
        </p>
        <p>
          商务负责人 <a href="https://t.me/usdd">@USDD</a>
        </p>
        <p>
          财务负责人 <a href="https://t.me/bill">@BiLL</a>
        </p>
        <p>
          供需负责人 <a href="https://t.me/zara">@ZARA</a>
        </p>
        <p>
          专群客服一 <a href="https://t.me/yuyu">@YUYU</a>
        </p>
        <p>
          专群客服二 <a href="https://t.me/gbpt">@GBPT</a>
        </p>
        <p>
          纠纷仲裁一 <a href="https://t.me/zoom">@ZOOM</a>
        </p>
        <p>
          纠纷仲裁二 <a href="https://t.me/sczl">@SCZL</a>
        </p>
        <p>
          纠纷接待一 <a href="https://t.me/ordi">@ORDI</a>
        </p>
        <p>
          纠纷接待二 <a href="https://t.me/sedo">@SEDO</a>
        </p>

        <h1 className="self-start font-bold	">机器人</h1>
        <p>
          公群管家 <a href="https://t.me/hzzvipbot">@BADBbot</a>
        </p>
        <p>
          自助发布 <a href="https://t.me/BADBGongXuBot">@BADBGongXuBot</a>
        </p>
        <h1 className="self-start font-bold	">举报/投诉/意见</h1>
        <p>
          币安担保•监督 <a href="https://t.me/JianDu">@JianDu</a>
        </p>
        <p>
          币安担保•监察 <a href="https://t.me/JianCha">@JianCha</a>
        </p>
      </div>
    </div>
  );
};

export default Right;
