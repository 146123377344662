import { createContext, useState,useEffect, useContext } from "react";

const HeaderActiveContext=createContext();

const HeaderActiveProvider = ({ children }) => {
  const [active, setActive] = useState(1);
  const path = window.location.pathname;
  useEffect(() => {
    
    if (path === "/") {
      setActive(1);
    } else if (path === "/gongqun") {
      setActive(2);
    } else if (path === "/search-list") {
      setActive(3);
    } else if (path === "/gongqun-center") {
      setActive(4);
    } else if (path === "/contact") {
      setActive(5);
    } else if (path === "/about") {
      setActive(6);
    } else {
      setActive(0);
    }
  }, [path]);
  return (
    <HeaderActiveContext.Provider value={{active,setActive}}>
      {children}
    </HeaderActiveContext.Provider>
  );
};
const useHeaderActive=()=>{
  const context=useContext(HeaderActiveContext);
  if(!context){
    throw new Error("useHeaderActive must be used within a HeaderActiveProvider");
  }
  return context;
}

export {HeaderActiveProvider,useHeaderActive} ;
