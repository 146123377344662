import Notice from "../Notice/Notice.component";
import Search from "../Components/Search.component";
const HomeMiddle = () => {
  return (
    <div className="w-[1200px] mx-auto h-[48px] flex gap-[20px] justify-between mt-[33px] mb-[43px]">
      <Notice width={`800px`}></Notice>
      <Search width="350px" />
    </div>
  );
};

export default HomeMiddle;
